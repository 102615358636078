<template>
  <v-card
    class="article-card align-center"
    :class="size"
    elevation="2"
    :to="{ name: article.articleType.name, params: { pageName: article.slug } }"
  >
    <div class="action-icons" v-if="isAuthenticated">
      <v-btn
        icon
        color="secondary"
        class="icon-wraper"
        @click.prevent.stop="toggleFav(article.articleId)"
      >
        <v-icon color="secondary">{{
          articleStatus(article.articleId) ? "$heartfull" : "$heart"
        }}</v-icon>
      </v-btn>
    </div>
    <v-img
      :src="refImageUrl"
      :alt="refImageAlt"
      :height="imgHeight"
      :max-height="imgHeight"
      onerror="this.src='/no-icon.png'"
    ></v-img>
    <v-card-subtitle
      class="article-type w-100 py-1"
      :class="courseClass?.toLowerCase()"
    >
      {{ course }}
    </v-card-subtitle>
    <v-card-title class="w-100 py-0"
      ><div>{{ article.title }}</div></v-card-title
    >
  </v-card>
</template>
<style lang="scss">
.article-card {
  height: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .action-icons {
    position: absolute;
    z-index: 2;
    left: 5px;
    top: 5px;
    .icon-wraper {
      background-color: white;
    }
  }
  .v-card__subtitle,
  .v-card__text,
  .v-card__title {
    padding: 8px;
  }
  .v-card__title {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-size: 16px;
    line-height: 18px;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
      width: 100%;
    }
  }
  .v-card__subtitle {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: $primary;
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import has from "lodash/has";

export default {
  name: "ArticleCard",
  props: {
    article: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: "normal"
    },
    confirmRemove: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      savedArticles: []
    };
  },
  computed: {
    ...mapGetters({
      getSavedArticles: "custom/getSavedArticles",
      isAuthenticated: "cart/isAuthenticated",
      user: "cart/getUser"
    }),
    refImageUrl() {
      return has(this.article, "media[0].url")
        ? this.article.media[0].url
        : this.article.refImageUrl
        ? this.article.refImageUrl
        : "";
    },
    refImageAlt() {
      return this.article.title || this.article.title;
    },
    imgHeight() {
      if (this.size == "small") {
        return 100;
      } else {
        return 175;
      }
    },
    course() {
      return this.article?.articleClass?.find(
        ac => ac.articleClassGroup.name == this.article.articleType.name
      )?.description;
    },
    courseClass() {
      return this.article?.articleClass?.find(
        ac => ac.articleClassGroup.name == this.article.articleType.name
      )?.name;
    },
    categories() {
      return this.article?.articleClass?.filter(
        ac => ac.articleClassGroup.name == "category"
      );
    }
  },
  methods: {
    ...mapActions({
      toggleArticle: "custom/toggleArticle"
    }),
    articleStatus(articleId) {
      return this.savedArticles.some(article => article === articleId);
    },
    toggleFav(id) {
      if (this.isAuthenticated) {
        if (this.articleStatus(id) && this.confirmRemove) {
          this.confirmChage(id);
        } else {
          this.saveNewState(id);
        }
      }
    },
    saveNewState(id) {
      this.toggleArticle(id);
      this.$emit("refresh");
    },
    async confirmChage(id) {
      let title = this.$t("myFavorites.removeFromFavorites") + " ";
      let res = await this.$dialog.confirm({
        text: `<h2 class='primary--text text-center'>${title}</h2><p class='text-center font-weight-bold'>Sei sicuro di voler rimuovere questo articolo dai preferiti?</p>`,
        class: "confirm-coupon-activation",
        actions: {
          false: {
            color: "var(--v-primary-base)",
            rounded: true,
            text: "Annulla"
          },
          true: {
            rounded: true,
            text: "Rimuovere"
          }
        }
      });
      if (res) {
        this.saveNewState(id);
      }
    }
  },
  mounted() {
    if (this.user && this.user.userId) {
      this.savedArticles = this.getSavedArticles(this.user.userId);
    } else {
      this.savedArticles = [];
    }
  }
};
</script>
